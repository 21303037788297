<template>
  <v-dialog transition="slide-y-transition" persistent v-model="show" max-width="550px">
    <v-card>
      <v-card-title>
        <app-id-icon />
        <span class="font-weight-bold tertiary--text title">
          {{ title }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-layout class="px-3" row align-center justify-center>
          <slot />
        </v-layout>
      </v-card-text>
      <v-card-actions class="pl-2">
        <v-btn :disabled="loading" color="primary" @click.stop="closeDialog">{{ cancelButtonText }}</v-btn>
        <v-spacer />
        <v-btn
          :loading="loading"
          :disabled="disabled"
          @click.native="loader = 'loading'"
          @click.stop="$emit('save')"
          color="primary"
        >
          {{ saveButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "Dialog",
    props: {
      show: {
        type: Boolean,
        required: true,
        default: false
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      title: {
        type: String,
        required: false,
        default: function () {
          return this.$t("message.credentials_have_changed");
        }
      },
      message: {
        type: String,
        required: false,
        default: function () {
          return this.$t("message.credentials_change_text");
        }
      },
      cancelButtonText: {
        type: String,
        required: false,
        default: function () {
          return this.$t("buttons.cancel");
        }
      },
      saveButtonText: {
        type: String,
        required: false,
        default: function () {
          return this.$t("buttons.save");
        }
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    methods: {
      closeDialog() {
        this.$emit("close");
      }
    }
  };
</script>

<style scoped>
  .v-card .title {
    margin-bottom: 0 !important;
  }
</style>
